// App.js

import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import Header from './components/layout/header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Maintenance from './pages/maintenanceMode';

const Home = lazy(() => import('./pages/Home'));
const Dashboard = lazy(() => import('./pages/admin/dashboard'));
const AboutUsPage = lazy(() => import('./pages/aboutUs/aboutUs'));

const PageNotFound = lazy(() => import('./components/PageNotFound'));
const PublicQuestionnaire = lazy(() => import('./pages/admin/publicQuestionnaire'));
const PublicViewQuestionnaire = lazy(() => import('./pages/admin/publicViewQuestionnaire'));
const ViewShowroomPartner = lazy(() => import('./pages/admin/ViewShowroomPartner'));
const LoadingBar = lazy(() => import('react-top-loading-bar'));
const ProtectedRoute = lazy(() => import('./components/restrictRoute/ProtectedRoute'));
const RedirectIfAuthenticated = lazy(() => import('./components/restrictRoute/RedirectIfAuthenticated'));
const ScrollToTop = lazy(() => import('./components/ScrolltoTop'));
const Footer = lazy(() => import('./components/layout/footer'));
const CustomerService = lazy(() => import('./pages/footerLinks/customerService'));
const ProductListingPage = lazy(() => import('./pages/productListingPage/[slugOrSku]'));
const SingleProduct = lazy(() => import('./pages/singleProduct/[slugOrSku]'));
const ComingSoon = lazy(() => import('./pages/comingSoon'));
const SearchResults = lazy(() => import('./pages/searchResult/[query]'));
const RangePage = lazy(() => import('./pages/ranges/[slug]'));
const Cart = lazy(() => import('./pages/cart/cart'));
const Warranty = lazy(() => import('./pages/support/warranty'));
const CompareProducts = lazy(() => import('./pages/support/CompareProducts'));
const ShareCart = lazy(() => import('./pages/cart/sharecart/[token]'));
const Login = lazy(() => import('./pages/auth/login'));
const ForgotPassword = lazy(() => import('./pages/auth/forgotPassword'));
const ResetPassword = lazy(() => import('./pages/auth/resetPassword'));
const SignUp = lazy(() => import('./pages/auth/signup'));
const MyOrders = lazy(() => import('./pages/admin/MyOrders'));
const MyImages = lazy(() => import('./pages/admin/MyImages'));
const DelieveryQuestionnaire = lazy(() => import('./pages/admin/DelieveryQuestionnaire'));
const PaymentMethod = lazy(() => import('./pages/admin/PaymentMethod'));
const AddressBook = lazy(() => import('./pages/admin/AddressBook'));
const Subscription = lazy(() => import('./pages/admin/Subscription'));
const AddNewAddress = lazy(() => import('./pages/admin/AddNewAddress'));
const ShowroomPartner = lazy(() => import('./pages/admin/ShowroomPartner'));
const EditAddress = lazy(() => import('./pages/admin/EditAddress'));
const PrivacyPolicyPage = lazy(() => import('./pages/footerLinks/privacyPolicy'));
const TermsConditionPage = lazy(() => import('./pages/footerLinks/termsAndCondition'));
const ReturnPolicyPage = lazy(() => import('./pages/footerLinks/returnPolicy'));
const ContactUsPage = lazy(() => import('./pages/support/contactUs'));
const TermsUsePage = lazy(() => import('./pages/footerLinks/termsOfUse'));
const MyAccount = lazy(() => import('./pages/admin/MyAccount'));
const MyWishlist = lazy(() => import('./pages/admin/MyWishlist'));
const OurTeamPage = lazy(() => import('./pages/aboutUs/OurTeam'));
const OurStoryPage = lazy(() => import('./pages/aboutUs/OurStory'));
const PartnerProgramPage = lazy(() => import('./pages/footerLinks/partnerProgram'));
const Blogs = lazy(() => import('./pages/aboutUs/blog/blogs'));
const BlogDetail = lazy(() => import('./pages/aboutUs/blog/blog/[url_key]'));
const Finance = lazy(() => import('./pages/support/finance'));
const VideoGallery = lazy(() => import('./pages/gallery/VideoGallery'));
const TrainingVideos = lazy(() => import('./pages/support/trainingVideos'));
const InStock = lazy(() => import('./pages/InStock'));
const PartsPage = lazy(() => import('./pages/support/replacementPartsPage'));
const Faq = lazy(() => import('./pages/support/faq'));
const FindDealerPage = lazy(() => import('./pages/footerLinks/findDealer'));
const ManualDocs = lazy(() => import('./pages/footerLinks/manualDocs'));
const SeriesPage = lazy(() => import('./pages/SeriesPage'));
const FaqQuestionsPage = lazy(() => import('./pages/support/faqQuestions'));
const ImageGallery = lazy(() => import("./pages/gallery/ImageGallery"));
const Unsubscribe = lazy(() => import("./pages/Unsubscribe"));

const DynamicComponent = () => {
  const { slugOrSku } = useParams();
  const catNames = [
    "dishwashers",
    "accessories",
    "refrigerators",
    "ventilation-hoods",
    "packages",
    "wall-ovens",
    "refurbished",
  ];


  return !catNames?.includes(slugOrSku) ? <SingleProduct /> : <ProductListingPage />;
};

// const MaintenanceComponent = () => {
//   const location = useLocation();
//   return location.pathname == "/DeveloperMode" ? (localStorage.setItem("devuser", true), <Home />) : <Maintenance />;
// };

const RemoveHtmlRedirect = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.endsWith('.html')) {
      const newPath = location.pathname.replace('.html', '');
      navigate(newPath, { replace: true });
    }
  }, [location, navigate]);

  return children;
};

const App = () => {
  // const isMaintenanceMode = localStorage.getItem("devuser");

  return (
    <Provider store={store}>
      <BrowserRouter>
        {/* {!isMaintenanceMode ? (
          <Routes>
            <Route path="*" element={<MaintenanceComponent />} title="Maintenance Mode" />
          </Routes>
        ) : (
          <> */}
            <Header />
            <main>
              <ToastContainer
                className="toast-container"
                theme="colored"
                closeOnClick
                position="top-right"
                closeButton={false}
              />
              <Suspense fallback={<LoadingBar color="#A18B64" containerClassName='custom-loader' />}>
                <ScrollToTop />
                <RemoveHtmlRedirect>
                  <Routes>
                    <Route path="/" element={<Home />} title="Home Page" />
                    <Route path="/ranges/:slug" element={<RangePage />} />
                    <Route path="/:slugOrSku" element={<DynamicComponent />} />

                    <Route path={`${process.env.REACT_APP_RANGE_PAGE}`} element={<SeriesPage />} />

                    <Route path="/in-stock" element={<InStock />} />
                    <Route path="/comingSoon" element={<ComingSoon />} />
                    <Route path="/catalogsearch/result/:query" element={<SearchResults />} />
                    <Route path="/warranty" element={<Warranty />} />
                    <Route path="/catalog/product-compare/" element={<CompareProducts />} />
                    <Route path="/manual/docs" element={<ManualDocs />} />
                    <Route path="/customer/service" element={<CustomerService />} />
                    <Route path="/faq/questions" element={<FaqQuestionsPage />} />
                    <Route path="/imageGallery/" element={<ImageGallery />} />
                    <Route path="/unsubscribe" element={<Unsubscribe />} />
                    <Route path="/explainer/video/index" element={<VideoGallery />} />
                    <Route path="/explainer/index/IlveRangeVideos" element={<TrainingVideos />} />
                    <Route path="/website-privacy-statement" element={<PrivacyPolicyPage />} />
                    <Route path="/terms-and-conditions" element={<TermsConditionPage />} />
                    <Route path="/terms" element={<TermsUsePage />} />
                    <Route path="/return-policy" element={<ReturnPolicyPage />} />
                    <Route path="/contact-us" element={<ContactUsPage />} />
                    <Route path="/homeshow" element={<PartnerProgramPage />} />
                    <Route path="/blog" element={<Blogs />} />
                    <Route path="/blog/:url_key" element={<BlogDetail />} />
                    <Route path="/services-and-parts" element={<PartsPage />} />
                    <Route path="/faq/index/" element={<Faq />} />
                    <Route path="/find-a-dealer" element={<FindDealerPage />} />
                    <Route path="/our-team" element={<OurTeamPage />} />
                    <Route path="/our-story" element={<OurStoryPage />} />
                    <Route path="/about-us" element={<AboutUsPage/>} />
                    <Route path="/financing" element={<Finance />} />
                    <Route path="/customer/account/forgotpassword/" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/sharecart/:token" element={<ShareCart />} />
                    <Route path="/checkout/cart" element={<Cart />} />

                    {/* Questionnaire public form route  */}
                    <Route path="/questionnaire/questionanswer/form" element={<PublicQuestionnaire />} />
                    <Route path="/publiclink/publiclink/index" element={<PublicViewQuestionnaire />} />

                    <Route element={<RedirectIfAuthenticated />}>
                      <Route path="/customer/account/login" element={<Login />} />
                      <Route path="/customer/account/create" element={<SignUp />} />
                    </Route>
                    <Route path="" element={<ProtectedRoute />}>
                      <Route path="" element={<Dashboard />}>
                        <Route path="/sales/order/history" element={<MyOrders />} />
                        <Route path="/imagegallery/gallery/myimages" element={<MyImages />} />
                        <Route path="/questionnaire/questionanswer/index" element={<DelieveryQuestionnaire />} />
                        <Route path="/stripe/customer/paymentmethods" element={<PaymentMethod />} />
                        <Route path="/customer/address" element={<AddressBook />} />
                        <Route path="/add/new/address" element={<AddNewAddress />} />
                        <Route path="/customer/address/edit/id/:id/" element={<EditAddress />} />
                        <Route path="/storelocation/customer/editlocation" element={<ViewShowroomPartner />} />
                        <Route path="/storelocation/customer/addlocation" element={<ShowroomPartner />} />
                        <Route path="/stripe/customer/subscriptions" element={<Subscription />} />
                        <Route path="/wishlist" element={<MyWishlist />} />
                        <Route path="/customer/account" element={<MyAccount />} />
                      </Route>
                    </Route>
                    <Route path="*" element={<PageNotFound />} />

                  </Routes>
                </RemoveHtmlRedirect>
                <Footer />
              </Suspense>
            </main>
          {/* </>)

        } */}
      </BrowserRouter>
    </Provider>
  );
};



export default App;
